<template>
  <div class="page1">
    <!-- 主体内容 -->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="一级分类">
                <el-input
                  v-model="formInline.titleName"
                  maxlength="20"
                  placeholder="请输入一级分类"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.subPage.setting.tabs.complainFirsts.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="$route.meta.authority.subPage.setting.tabs.complainFirsts.button.add"
                @click="add()"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button type="info" @click="complainManageTag()">返回</el-button>
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <!-- v-if='$route.meta.authority.button.view || $route.meta.authority.button.edit' || $route.meta.authority.button.configure' -->
          <el-table-column
            label="操作"
            align="center"
            v-if="
              $route.meta.authority.subPage.setting.tabs.complainFirsts.button.detail ||
              $route.meta.authority.subPage.setting.tabs.complainFirsts.button.edit
            "
            width="90"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.subPage.setting.tabs.complainFirsts.button.detail"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.subPage.setting.tabs.complainFirsts.button.edit"
                    >编辑</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDt } from "@/common/js/public";
export default {
  name: "levelOne",
  data() {
    return {
      formInline: {
        titleName: "",
      },
      titleName: "",
      loading: false,
      tableCols: [
        {
          prop: "classifyOneName",
          label: "一级分类",
          width: "",
        },
        {
          prop: "remarks",
          label: "备注",
          width: "",
        },
      ],
      tableData: [],
      page: 0,
      pageSize: 15,
      total: 0,
    };
  },
  methods: {
    complainManageTag() {
      if (this.$store.state.complainManageTag == false) {
        this.$store.commit("SET_ComplainManageTag", true);
      }
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/customerClassify/list", {
          data: {
            classify: this.formInline.titleName,
            type: 1,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    querySearch(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/customerClassify/list",
        data: {
          classifyOne: this.formInline.titleName,
          type: 1,
          pageNum: this.page,
          pageSize: this.pageSize,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.list.forEach((v) => {
              v.value = v.titleName;
            });
            cb(res.value.list);
          }
        },
      };
      getDt(opt);
    },
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
    },
    add() {
      this.$router.push({
        path: "/levelOneAddType",
        query: {
          typeCode: "1",
          operation: "添加",
        },
      });
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.$router.push({
          path: "/seeTypeOne",
          query: {
            operation: "查看",
            ...data,
          },
        });
      }
      if (cmd === "b") {
        // 编辑
        this.$router.push({
          path: "/levelOneAddType",
          query: {
            operation: "编辑",
            ...data,
          },
        });
      }
    },
  },
  mounted() {
    this.searchData();
    console.log("一级分类==>", this.$route.meta.authority);
  },
  activated() {
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped></style>
